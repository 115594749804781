import React, { useState, useEffect, useRef } from 'react';
import { AxiosInstance } from '../component/AxiosInstance';
import { formatDistanceToNow } from 'date-fns';
import { useNavigate } from 'react-router-dom';

interface Headline {
  id: number;
  image: string;
  created_at: string;
  title: string;
  description: string;
  slug: string;
}

const Headlines: React.FC = () => {
  const [headlines, setHeadlines] = useState<Headline[]>([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const totalSlides = headlines.length;
  const sliderRef = useRef<HTMLDivElement>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const navigate = useNavigate()

  useEffect(() => {
    const fetchHeadlines = async () => {
      try {
        const response = await AxiosInstance.get('/create-headlines/');
        if (response.status === 200) {
          setHeadlines(response.data);
          console.log(response)
        }
      } catch (error) {
        console.error('Error fetching headlines:', error);
      }
    };

    fetchHeadlines();

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      if (sliderRef.current) {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
      }
    }, 5000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [totalSlides]);

  const goToSlide = (slideIndex: number) => {
    if (sliderRef.current) {
      setCurrentSlide(slideIndex);
    }
  };

  const handleMouseEnter = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
  };

  const handleMouseLeave = () => {
    if (sliderRef.current) {
      intervalRef.current = setInterval(() => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % totalSlides);
      }, 5000);
    }
  };

  const renderSlides = () => {
    const visibleSlides = headlines.slice(currentSlide, currentSlide + 3);

    return visibleSlides.map((slide, index) => (
      <div key={slide.id} className={`borders  ${index === 0 ? 'swiper-slide swiper-slide-active blog-slider__item' : ''}`}>
        {index === 0 && <> <div className="blog-slider__img">
          <img src={slide.image} alt="" />
        </div></>}
        <div className="blog-slider__content borders">
          <span className="blog-slider__code">{index === 0 && formatDistanceToNow(new Date(slide.created_at))}</span>
          <div className="blog-slider__title">{index === 0 && slide.title}</div>
          <div className="blog-slider__text">{index === 0 &&
            slide.description.length > 200
            ? `${slide.description.slice(0, 200)}...`
            : slide.description
          }
          </div>
          {index === 0 && <button onClick={() => navigate(`/article/${slide.slug}`)} type='button' className="blog-slider__button">
            READ MORE
          </button>}
        </div>
      </div>
    ));
  };

  return (
    <div
      className="blog-slider"
      ref={sliderRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="blog-slider__wrp swiper-wrapper">{renderSlides()}</div>
      <div className="blog-slider__pagination">
        {headlines.map((_, index) => (
          <div
            key={index}
            className={`swiper-pagination-bullet ${index === currentSlide ? 'swiper-pagination-bullet-active' : ''}`}
            onClick={() => goToSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Headlines;