import React, { useEffect, useState, useCallback } from 'react';
import { Button, Card, Col, Form, Input, Row, Select, Spin, Switch, Typography, Upload, notification } from 'antd';
import { ProductAxiosInstance } from '../AxiosInstance';
import { FaSpinner } from 'react-icons/fa6';
import { UploadOutlined } from '@ant-design/icons';
import BackButton from '../BackButton';
import Logo from '../logo.png'
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import Logout from './Logout'

const { Option } = Select;
const { Title } = Typography;

interface Guide {
    update_review: boolean;
    id: number;
    name: string;
    details: string;
    image: string | null;
    slug: string;
}

interface GuideSubCategory {
    id: number;
    name: string;
    topics: Guide[];
}

interface GuideCategory {
    id: number;
    name: string;
    subcategories: GuideSubCategory[];
}

const ManageGuide: React.FC = () => {
    const [categories, setCategories] = useState<GuideCategory[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [image, setImage] = useState<File | null>(null);
    const [filteredSubCategories, setFilteredSubCategories] = useState<GuideSubCategory[]>([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [filteredTopics, setFilteredTopics] = useState<Guide[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const navigate = useNavigate()

    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const [form3] = Form.useForm();

    useEffect(() => {
        const admin = localStorage.getItem('admin')
        if (!admin) {
            toast.error('Unauthorized')

            setTimeout(() => {
                navigate('/admin/login', { replace: true })
            }, 2000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            const { data } = await ProductAxiosInstance.get('/classified-guides/');
            setCategories(data);
        } catch (error) {
            console.error('Error fetching data:', error);
            notification.error({
                message: 'Error',
                description: 'Failed to fetch data',
            });
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleCreateCategory = async (values: any) => {
        try {
            await ProductAxiosInstance.post('/guide-categories/', values);
            fetchData();
            notification.success({
                message: 'Success',
                description: 'Guide category created successfully',
            });
            form1.resetFields();
        } catch (error) {
            console.error('Error creating guide category:', error);
            notification.error({
                message: 'Error',
                description: 'Failed to create guide category',
            });
        }
    };

    const handleCreateSubCategory = async (values: any) => {
        try {
            await ProductAxiosInstance.post('/guide-subcategories/', values);
            fetchData();
            notification.success({
                message: 'Success',
                description: 'Guide subcategory created successfully',
            });
            form2.resetFields();
        } catch (error) {
            console.error('Error creating guide subcategory:', error);
            notification.error({
                message: 'Error',
                description: 'Failed to create guide subcategory',
            });
        }
    };

    const handleCreateTopic = async (values: any) => {
        const formData = new FormData();
        formData.append('name', values.name);
        formData.append('details', values.details);
        formData.append('category', values.category);
        formData.append('subcategory', values.subcategory);
        formData.append('update_review', 'true');
        console.log(image)
        if (image) {
            formData.append('image', image);
        }

        try {
            await ProductAxiosInstance.post('/guide-topics/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            fetchData();
            notification.success({
                message: 'Success',
                description: 'Guide topic created successfully',
            });
            form3.resetFields();
        } catch (error) {
            console.error('Error creating guide topic:', error);
            notification.error({
                message: 'Error',
                description: 'Failed to create guide topic',
            });
        }
    };

    const handleDelete = async (url: string, successMessage: string) => {
        try {
            await ProductAxiosInstance.delete(url);
            fetchData();
            notification.success({
                message: 'Success',
                description: successMessage,
            });
        } catch (error) {
            console.error(`Error deleting ${url.split('/')[1]}:`, error);
            notification.error({
                message: 'Error',
                description: `Failed to delete ${url.split('/')[1]}`,
            });
        }
    };

    const handleDeleteCategory = (id: number) => handleDelete(`/guide-categories/${id}/`, 'Guide category deleted successfully');
    const handleDeleteSubCategory = (id: number) => handleDelete(`/guide-subcategories/${id}/`, 'Guide subcategory deleted successfully');
    const handleDeleteTopic = (id: number) => handleDelete(`/guide-topics/${id}/`, 'Guide topic deleted successfully');

    const handleUpdateReviewStatus = async (id: number, update_review: boolean) => {
        try {
            await ProductAxiosInstance.patch(`/guide-topics/${id}/`, { update_review });
            fetchData();
            notification.success({
                message: 'Success',
                description: 'Guide topic review status updated successfully',
            });
        } catch (error) {
            console.error('Error updating review status:', error);
            notification.error({
                message: 'Error',
                description: 'Failed to update review status',
            });
        }
    };

    const handleImageChange = (info: any) => {
        if (info.file) {
            setImage(info.fileList[0].originFileObj);
        }
    };

    const handleCategoryChange = (categoryId: number) => {
        const selectedCategory = categories.find(category => category.id === categoryId);
        if (selectedCategory) {
            setFilteredSubCategories(selectedCategory.subcategories);
        }
    };

    const handleSubCategoryChange = (subCategoryId: number) => {
        const selectedSubCategory = filteredSubCategories.find(subcategory => subcategory.id === subCategoryId);
        if (selectedSubCategory) {
            setFilteredTopics(selectedSubCategory.topics);
        }
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value.toLowerCase());
    };

    const filteredCategories = categories.filter(category =>
        category.name.toLowerCase().includes(searchQuery) ||
        category.subcategories.some(subcategory =>
            subcategory.name.toLowerCase().includes(searchQuery) ||
            subcategory.topics.some(topic => topic.name.toLowerCase().includes(searchQuery))
        )
    );

    return (
        <div className='m-3'>
            <Logout />
            <center>
                <img
                    src={Logo}
                    alt="Dynamiqtec Logo"
                    className='logo'
                    onClick={() => navigate('/')}
                />
            </center>
            <h1 className='my-title m-3'>Manage Guides and Comparisons</h1>
            <BackButton />
            <Input.Search
                placeholder="Search for a category, subcategory, or topic"
                onChange={handleSearchChange}
                style={{ marginBottom: 20 }}
                className='search-input'
            />

            <Row gutter={16} className='mt-4'>
                <Col span={8}>
                    <Card title="Create Guide Category">
                        <Form onFinish={handleCreateCategory} form={form1}>
                            <Form.Item name="name" label="Category Name" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">Create</Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="Create Guide SubCategory">
                        <Form onFinish={handleCreateSubCategory} form={form2}>
                            <Form.Item name="category" label="Category" rules={[{ required: true }]}>
                                <Select onChange={handleCategoryChange}>
                                    {categories.map((category) => (
                                        <Option key={category.id} value={category.id}>{category.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item name="name" label="SubCategory Name" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">Create</Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="Create Guide Topic">
                        <Form onFinish={handleCreateTopic} form={form3}>
                            <Form.Item name="name" label="Topic Name" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="details" label="Topic Details">
                                <Input.TextArea />
                            </Form.Item>
                            <Form.Item name="category" label="Category" rules={[{ required: true }]}>
                                <Select onChange={handleCategoryChange}>
                                    {categories.map((category) => (
                                        <Option key={category.id} value={category.id}>{category.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item name="subcategory" label="SubCategory" rules={[{ required: true }]}>
                                <Select onChange={handleSubCategoryChange}>
                                    {filteredSubCategories.map((subcategory) => (
                                        <Option key={subcategory.id} value={subcategory.id}>{subcategory.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item name="image" label="Topic Image">
                                <Upload
                                    beforeUpload={() => false}
                                    onChange={handleImageChange}
                                    maxCount={1}
                                    accept=".jpg,.jpeg,.png,.gif,.bmp"
                                >
                                    <Button icon={<UploadOutlined />}>Upload Image</Button>
                                </Upload>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">Create</Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>

            {loading ? (
                <center><Spin indicator={<FaSpinner className="spinner" />} spinning={loading} /></center>
            ) : (
                filteredCategories.map((category) => (
                    <div key={category.id} style={{ marginBottom: '40px' }}>
                        <Title className='category-name' level={2}>{category.name}
                            <Button className='m-2' type="dashed" onClick={() => handleDeleteCategory(category.id)} danger>Delete</Button>
                        </Title>
                        <Row gutter={[16, 16]}>
                            {category.subcategories.map((subcategory) => (
                                <Col key={subcategory.id} xs={24} sm={12} md={8} lg={8}>
                                    <Card
                                        hoverable
                                        title={subcategory.name}
                                        extra={
                                            <Button type="dashed" onClick={() => handleDeleteSubCategory(subcategory.id)} danger>Delete</Button>
                                        }
                                    >
                                        <ul className='product-ul'>
                                            {subcategory.topics.map((topic) => (
                                                <li className='product-li-adm' key={topic.id}>
                                                    {topic.name}
                                                    <Switch
                                                        checked={topic.update_review}
                                                        onChange={(checked) => handleUpdateReviewStatus(topic.id, checked)}
                                                        checkedChildren="Update Review"
                                                        unCheckedChildren="No Update"
                                                    />
                                                    <Button type="link" onClick={() => handleDeleteTopic(topic.id)}>Delete</Button>
                                                </li>
                                            ))}
                                        </ul>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </div>
                ))
            )}
        </div>
    );
};

export default ManageGuide;