import axios from 'axios'

export const AxiosInstance = axios.create({
    baseURL: 'https://backend.dynamiqtec.com/api',
    headers: {
        "Content-Type": "application/json"
    }
})


export const ProductAxiosInstance = axios.create({
    baseURL: 'https://backend.dynamiqtec.com/api-products',
    headers: {
        "Content-Type": "application/json"
    }
})


export const NewsletterAxiosInstance = axios.create({
    baseURL: 'https://backend.dynamiqtec.com/newsletter',
    headers: {
        "Content-Type": "application/json"
    }
})
