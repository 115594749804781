import React, { useState } from 'react'
import { Button, Card, Form, Input, Spin } from 'antd';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { FaSpinner } from 'react-icons/fa6';
import { AxiosInstance } from '../component/AxiosInstance';

type FieldType = {
    user?: string;
    password?: string;
};


const AdminLogin: React.FC = () => {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const onFinish = async (values: any) => {
        setLoading(true);
        try {
            const response = await AxiosInstance.post(`/auth-admin/`, values);
            if (response.status === 200) {
                toast.success('Success')
                localStorage.setItem('admin', 'true')
                localStorage.setItem('admin-name', values.user)
                navigate('/admin/dashboard');
            }
        } catch (error) {
            toast.error('Unauthorized')
            setTimeout(() => {
                navigate('/')
            }, 2500);
        } finally {
            setLoading(false);
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <>
            <Toaster />
            <Card title="Admin login" className='mx-auto mt-5' style={{ width: 350 }}>
                <Form
                    name=" Admin login"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Form.Item<FieldType>
                        label="Username"
                        name="user"
                        rules={[{ required: true, message: 'Please input your username!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item<FieldType>
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                        {loading ?
                            <center>
                                <Spin indicator={<FaSpinner className="spinner" />} spinning={loading} />
                            </center>
                            :
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>}
                    </Form.Item>
                </Form>
                {/* <p>New here ? <Button onClick={() => navigate('/signup')} type='link'>Signup</Button></p> */}
            </Card>
        </>
    )
}

export default AdminLogin