import React from 'react';
import { Card, Typography, Layout } from 'antd';
import Logo from './logo1.png'
import BackButton from './BackButton';

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const PrivacyPolicy: React.FC = () => {
    return (
        <Layout style={{ padding: '20px', background: '#fff' }}>
            <BackButton />
            <Content style={{ maxWidth: '800px', margin: '0 auto' }}>
                <img
                    src={Logo}
                    alt="Dynamiqtec Logo"
                    className='big-logo'
                />
                <Title level={2}>Privacy Policy</Title>
                <Card>
                    <Title level={3}>Introduction</Title>
                    <Paragraph>
                        Welcome to Dynamiqtec. We are committed to protecting your personal information and your right to privacy.
                        If you have any questions or concerns about this privacy notice, or our practices with regards to your
                        personal information, please contact us.
                    </Paragraph>

                    <Title level={3}>Information We Collect</Title>
                    <Paragraph>
                        We collect personal information that you voluntarily provide to us when you register on the website,
                        express an interest in obtaining information about us or our products and services, when you participate
                        in activities on the website or otherwise when you contact us.
                    </Paragraph>

                    <Title level={3}>How We Use Your Information</Title>
                    <Paragraph>
                        We use personal information collected via our website for a variety of business purposes described below.
                        We process your personal information for these purposes in reliance on our legitimate business interests,
                        in order to enter into or perform a contract with you, with your consent, and/or for compliance with our
                        legal obligations.
                    </Paragraph>

                    <Title level={3}>Sharing Your Information</Title>
                    <Paragraph>
                        We only share information with your consent, to comply with laws, to provide you with services, to protect
                        your rights, or to fulfill business obligations.
                    </Paragraph>

                    <Title level={3}>Data Retention</Title>
                    <Paragraph>
                        We will only keep your personal information for as long as it is necessary for the purposes set out in
                        this privacy notice, unless a longer retention period is required or permitted by law.
                    </Paragraph>

                    <Title level={3}>Your Privacy Rights</Title>
                    <Paragraph>
                        Depending on your location, you may have certain rights regarding your personal information, including the
                        right to access, correct, or delete your personal information.
                    </Paragraph>

                    <Title level={3}>Contact Us</Title>
                    <Paragraph>
                        If you have questions or comments about this policy, you may email us at info@dynamiqtec.com:
                        <br />
                        Dynamiqtec<br />
                    </Paragraph>
                </Card>
            </Content>
        </Layout>
    );
};

export default PrivacyPolicy;