import React, { useState, useEffect } from 'react';
import { Table, Button, notification, Modal } from 'antd';
import { AxiosInstance } from '../AxiosInstance';
import moment from 'moment';

interface ContactMessage {
    id: number;
    name: string;
    email: string;
    subject: string;
    message: string;
    created_at: string;
}

const ManageMessages: React.FC = () => {
    const [messages, setMessages] = useState<ContactMessage[]>([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchMessages();
    }, []);

    const fetchMessages = async () => {
        try {
            const response = await AxiosInstance.get<ContactMessage[]>('/contact-messages/');
            setMessages(response.data);
        } catch (error) {
            console.error('Error fetching messages:', error);
        }
    };

    const handleDelete = async () => {
        setLoading(true);
        try {
            await AxiosInstance.post('/contact-messages/delete/', {
                ids: selectedRowKeys,
            });
            notification.success({
                message: 'Messages Deleted',
                description: 'Selected messages have been deleted successfully.',
            });
            setSelectedRowKeys([]);
            fetchMessages();
        } catch (error) {
            console.error('Error deleting messages:', error);
            notification.error({
                message: 'Failed to Delete Messages',
                description: 'There was an error deleting the selected messages. Please try again.',
            });
        } finally {
            setLoading(false);
        }
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Subject',
            dataIndex: 'subject',
            key: 'subject',
        },
        {
            title: 'Message',
            dataIndex: 'message',
            key: 'message',
        },
        {
            title: 'Received At',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (text: string) => moment(text).format('LLL'),
        },
    ];

    const rowSelection = {
        selectedRowKeys,
        onChange: (selectedKeys: React.Key[]) => setSelectedRowKeys(selectedKeys),
    };

    return (
        <div style={{ padding: '20px' }}>
            <h1 className='my-title'>Manage Messages</h1>
            <Button
                type="primary"
                danger
                onClick={() =>
                    Modal.confirm({
                        title: 'Confirm Deletion',
                        content: 'Are you sure you want to delete the selected messages?',
                        onOk: handleDelete,
                    })
                }
                disabled={selectedRowKeys.length === 0}
                loading={loading}
                style={{ marginBottom: '10px' }}
            >
                Delete Selected
            </Button>
            <Table
                rowKey="id"
                dataSource={messages}
                columns={columns}
                rowSelection={rowSelection}
                pagination={{ pageSize: 10 }}
            />
        </div>
    );
};

export default ManageMessages;