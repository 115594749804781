import React, { useState, useEffect } from 'react';
import { Button, Input, List, Space, Modal, Form, Upload, Card, Spin } from 'antd';
import { EditOutlined, DeleteOutlined, InboxOutlined } from '@ant-design/icons';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa6';
import { AxiosInstance } from '../AxiosInstance';
import BackButton from '../BackButton';
import Logo from '../logo.png'
import Logout from './Logout'

const { TextArea } = Input;
const { Dragger } = Upload;

type Headline = {
    title: string;
    content: string;
    description?: string;
    image?: string;
    slug?: string;
    category?: string[];
};

const CreateHeadlines: React.FC = () => {
    const [form] = Form.useForm();
    const [headlines, setHeadlines] = useState<Headline[]>([]);
    const [selectedHeadline, setSelectedHeadline] = useState<Headline | null>(null);
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [createLoading, setCreateLoading] = useState(false)

    useEffect(() => {
        const admin = localStorage.getItem('admin')
        if (!admin) {
            toast.error('Unauthorized')
            setTimeout(() => {
                navigate('/admin/login', { replace: true })
            }, 2000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleAddHeadline = async () => {
        try {
            setCreateLoading(true)

            const values = await form.validateFields();
            values.category = Array.isArray(values.category) ? values.category : [values.category];
            const file = values.image.fileList[0].originFileObj;
            const formData = new FormData();
            formData.append('image', file);
            formData.append('title', values.title);
            formData.append('content', values.content);
            formData.append('description', values.description);

            const response = await AxiosInstance.post(`/create-headlines/`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });


            if (response.status === 201) {
                setHeadlines([...headlines, response.data]);
                form.resetFields();
                toast.success('Headline added successfully!');
            } else {
                toast.error('Failed to add headline. Please try again.');
            }
        } catch (error) {
            toast.error('An error occurred. Please try again.');
        } finally {
            setCreateLoading(false)
        }
    };


    const handleEditHeadline = (headline: Headline) => {
        setSelectedHeadline(headline);
        showModal();
    };

    const handleUpdateHeadline = async () => {
        try {
            const values = await form.validateFields();

            // Check if there's a selected headline
            if (selectedHeadline) {
                // Create FormData and append updated values
                const formData = new FormData();
                formData.append('title', values.title);
                formData.append('content', values.content);
                formData.append('description', values.description);

                // Check if the category is an array, if not, convert it to an array
                formData.append('category', Array.isArray(values.category) ? values.category : [values.category]);


                const file = values.image.fileList[0].originFileObj;
                formData.append('image', file);


                // Make a PUT request to update the selected headline
                const response = await AxiosInstance.put(`/create-headlines/${selectedHeadline.slug}/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });

                // Check the response status
                if (response.status === 200) {
                    // Update the headlines state
                    const updatedHeadlines = headlines.map((h) => (h.slug === selectedHeadline.slug ? response.data : h));
                    setHeadlines(updatedHeadlines);

                    // Reset form fields and close the modal
                    form.resetFields();
                    setSelectedHeadline(null);
                    hideModal();

                    // Show success toast
                    toast.success('Headline updated successfully!');
                } else {
                    toast.error('Failed to update headline. Please try again.');
                }
            }
        } catch (error) {
            console.log(error)
            toast.error('An error occurred. Please try again.');
        }
    };

    const handleDeleteHeadline = async (headline: Headline) => {
        try {
            const response = await AxiosInstance.delete(`/create-headlines/${headline.slug}/`);

            if (response.status === 204) {
                const updatedHeadlines = headlines.filter((h) => h.slug !== headline.slug);
                setHeadlines(updatedHeadlines);
                toast.success('Headline deleted successfully!');
            } else {
                toast.error('Failed to delete headline. Please try again.');
            }
        } catch (error) {
            toast.error('An error occurred. Please try again.');
        }
    };


    // Ant Design Modal
    const [isModalVisible, setIsModalVisible] = useState(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const hideModal = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        if (selectedHeadline) {
            // Populate form fields with selected headline data
            form.setFieldsValue({
                title: selectedHeadline.title,
                content: selectedHeadline.content,
                description: selectedHeadline.description,
                category: selectedHeadline?.category ? selectedHeadline.category[0] : '',
                image: [{
                    uid: '-1',
                    name: 'image.png',
                    status: 'done',
                    url: selectedHeadline.image,
                }],
            });

            showModal();
        }
    }, [selectedHeadline, form]);

    useEffect(() => {
        const admin = localStorage.getItem('admin')
        if (!admin) {
            toast.error('Unauthorized')
            navigate('/admin/login', { replace: true })
        }
        const fetchNewsHeadLines = async () => {
            setLoading(true);
            try {
                const response = await AxiosInstance.get(`/create-headlines/`);
                if (response.status === 200) {
                    setHeadlines(response.data);
                }
            } catch (error) {
                toast.error('An Error occured')
            } finally {
                setLoading(false);
            }
        }
        fetchNewsHeadLines()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <div className='m-4'>
            <Logout />
            <center>
                <img
                    src={Logo}
                    alt="Dynamiqtec Logo"
                    className='logo'
                    onClick={() => navigate('/')}
                />
            </center>
            <h1 className='title mt-3 text-center'>Create Update and Delete Headlines</h1>

            <BackButton />
            <div className='mt-5' style={{ display: 'flex' }}>
                <Toaster />
                {/* Part 1: Display List of Headlines */}
                <div style={{ flex: 1, marginRight: '20px' }}>

                    <Card title='Headlines'>
                        {loading ?
                            <center>
                                <Spin indicator={<FaSpinner className="spinner" />} spinning={loading} />
                            </center>
                            :
                            <List
                                dataSource={headlines}
                                renderItem={(headline) => (
                                    <List.Item>
                                        {headline.title}
                                        <Space>
                                            <Button icon={<EditOutlined />} onClick={() => handleEditHeadline(headline)}>
                                                Edit
                                            </Button>
                                            <Button icon={<DeleteOutlined />} onClick={() => handleDeleteHeadline(headline)}>
                                                Delete
                                            </Button>
                                        </Space>
                                    </List.Item>
                                )}
                            />}
                    </Card>
                </div>


                <div style={{ flex: 1 }}>
                    <Card title='Create'>
                        <Form
                            form={form}
                            layout="vertical"
                            encType="multipart/form-data"
                        >
                            <Form.Item
                                label="Title"
                                name="title"
                                rules={[{ required: true, message: 'Please enter a title' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Content"
                                name="content"
                                rules={[{ required: true, message: 'Please enter content' }]}
                            >
                                <TextArea />
                            </Form.Item>
                            <Form.Item
                                label="Description"
                                name="description"
                                rules={[{ required: true, message: 'Please enter a description' }]}
                            >
                                <TextArea />
                            </Form.Item>

                            <Form.Item
                                label="Category"
                                name="category"
                                rules={[{ required: true, message: 'Please enter a category' }]}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                label="Image"
                                name="image"
                                rules={[{ required: true, message: 'Please upload an image' }]}
                            >
                                <Dragger>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined />
                                    </p>
                                    <p className="ant-upload-text">Click or drag image to this area to upload</p>
                                </Dragger>
                            </Form.Item>

                            <Space>
                                {createLoading ?
                                    <center>
                                        <Spin indicator={<FaSpinner className="spinner" />} spinning={loading} />
                                    </center>
                                    : <>
                                        <Button type="primary" onClick={selectedHeadline ? handleUpdateHeadline : handleAddHeadline}>
                                            {selectedHeadline ? 'Update Headline' : 'Add Headline'}
                                        </Button>
                                        <Button onClick={() => form.resetFields()}>Clear</Button>
                                    </>}
                            </Space>
                        </Form>
                    </Card>
                </div>

                {/* Modal for Editing Headline */}
                <Modal title="Edit Headline" visible={isModalVisible} onOk={handleUpdateHeadline} onCancel={hideModal}>
                    <Form form={form} layout="vertical">
                        <Form.Item
                            label="Title"
                            name="title"
                            rules={[{ required: true, message: 'Please enter a title' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Content"
                            name="content"
                            rules={[{ required: true, message: 'Please enter content' }]}
                        >
                            <TextArea />
                        </Form.Item>
                        <Form.Item label="Description" name="description">
                            <TextArea />
                        </Form.Item>

                        <Form.Item
                            label="Category"
                            name="category"
                            rules={[{ required: true, message: 'Please enter a category' }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label="Image"
                            name="image"
                            rules={[{ required: true, message: 'Please upload an image' }]}
                        >
                            <Dragger>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-text">Click or drag image to this area to upload</p>
                            </Dragger>
                        </Form.Item>

                    </Form>
                </Modal>
            </div>
        </div>
    );
};

export default CreateHeadlines;