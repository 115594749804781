import React, { useEffect, useState, useRef } from 'react';
import Article from './Article';
// import { Divider } from 'antd';
import { AxiosInstance } from '../component/AxiosInstance';
import { FaSpinner } from 'react-icons/fa6';
import { Spin } from 'antd';

const Articles: React.FC = () => {
    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [nextUrl, setNextUrl] = useState<string | null>(null);
    const observer = useRef<IntersectionObserver | null>(null);
    const lastArticleRef = useRef<HTMLDivElement | null>(null);

    const fetchMoreArticles = async () => {
        if (nextUrl) {
            const nextUrl1 = 'https:'+nextUrl.split(':')[1];
            setLoading(true);
            try {
                const response = await AxiosInstance.get(nextUrl1);
                if (response.status === 200) {
                    setData((prevData) => [...prevData, ...response.data.results]);
                    setNextUrl(response.data.next);
                } else {
                    console.log(response.status);
                }
            } catch (error) {
                console.error('Error: ', error);
            } finally {
                setLoading(false);
            }
        }
    };

    useEffect(() => {
        const fetchArticles = async () => {
            setLoading(true);
            try {
                const response = await AxiosInstance.get('/articles/');
                if (response.status === 200) {
                    setData(response.data.results);
                    setNextUrl(response.data.next);
                } else {
                    console.log(response.status);
                }
            } catch (error) {
                console.error('Error: ', error);
            } finally {
                setLoading(false);
            }
        };
        fetchArticles();
    }, []);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '20px',
            threshold: 0.1,
        };

        observer.current = new IntersectionObserver(([entry]) => {
            if (entry.isIntersecting) {
                fetchMoreArticles();
            }
        }, options);

        if (lastArticleRef.current) {
            observer.current.observe(lastArticleRef.current);
        }

        return () => {
            if (observer.current) {
                observer.current.disconnect();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nextUrl]);

    return (
        <div>
            {data.map((article, index) => (
                <>
                    <Article
                        key={index}
                        id={article.id}
                        title={article.title}
                        image={article.image}
                        content={article.description}
                        likes={article.like_count}
                        dislikes={article.dislike_count}
                        share={article.share_count}
                        source={article.source}
                        source_link={article.source_link}
                        tags={article.tags}
                        slug={article.slug}
                        date={article.created_at}
                        dynamiqtec1={article.dynamiqtec1}
                        dynamiqtec2={article.dynamiqtec2}
                        dynslug={article.dynslug}
                    />
                </>
            ))}

            {loading && <p><center><Spin indicator={<FaSpinner className="spinner" />} spinning={loading} /></center></p>}

            <div ref={lastArticleRef} />
        </div>
    );
};

export default Articles;