import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { IoArrowBackSharp } from "react-icons/io5";

const BackButton: React.FC = () => {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1);
    };

    return (
        <Button onClick={goBack}>
            <IoArrowBackSharp/>
        </Button>
    )
};

export default BackButton;