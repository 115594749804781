import React, { useEffect, useState } from 'react';
import { Input, Button, Card, Modal, Spin, message } from 'antd';
import { AxiosInstance } from '../AxiosInstance';
import BackButton from '../BackButton';
import { useNavigate } from 'react-router-dom';
import Logo from '../logo.png'
import toast from 'react-hot-toast';
import Logout from './Logout'

const { confirm } = Modal;

const ManageArticles: React.FC = () => {
    const [articleUrl, setArticleUrl] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [articleDetails, setArticleDetails] = useState<{ title: string; description: string; image: string } | null>(null);
    const navigate = useNavigate()

    const handleArticleUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setArticleUrl(e.target.value);
    };

    useEffect(() => {
        const admin = localStorage.getItem('admin')
        if (!admin) {
            toast.error('Unauthorized')

            setTimeout(() => {
                navigate('/admin/login', { replace: true })
            }, 2000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleFetchArticleDetails = async () => {
        try {
            setLoading(true);
            // Extract slug from the provided URL
            const urlParts = new URL(articleUrl);
            const articleSlug = urlParts.pathname.split('/').pop();

            // Make a request to retrieve article details
            const response = await AxiosInstance.get(`/articles/${articleSlug}/retrieve_by_slug/`);

            if (response.status === 200) {
                setArticleDetails({
                    title: response.data.title,
                    description: response.data.description,
                    image: response.data.image,
                });
            } else {
                message.error('Failed to retrieve article details. Please try again.');
            }
        } catch (error) {

            message.error('An error occurred. Please check the provided URL.');
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteArticle = () => {
        confirm({
            title: 'Are you sure you want to delete this article?',
            content: (
                <div>
                    <p>Title: {articleDetails?.title}</p>
                    <p>Description: {articleDetails?.description}</p>
                </div>
            ),
            onOk: async () => {
                try {
                    const urlParts = new URL(articleUrl);
                    const articleSlug = urlParts.pathname.split('/').pop();

                    const response = await AxiosInstance.delete(`/articles/${articleSlug}/`);

                    if (response.status === 204) {
                        message.success('Article deleted successfully.');
                        setArticleDetails(null);
                        setArticleUrl('');
                    } else {
                        message.error('Failed to delete the article. Please try again.');
                    }
                } catch (error) {
                    message.error('An error occurred. Please try again.');
                }
            },
        });
    };

    return (
        <center className='m-3'>
            <Logout />
            <img
                src={Logo}
                alt="Dynamiqtec Logo"
                className='logo'
                onClick={() => navigate('/')}
            />
            <h1>Manage Articles</h1>
            <BackButton /><br /><br />
            <Card title="Delete an Article" style={{ width: 500 }}>
                <Input
                    placeholder="Enter Article URL"
                    value={articleUrl}
                    onChange={handleArticleUrlChange}
                    style={{ marginBottom: '10px' }}
                />
                <Button type="primary" onClick={handleFetchArticleDetails} style={{ marginRight: '10px' }}>
                    Fetch Article Details
                </Button>
                <Button onClick={handleDeleteArticle} disabled={!articleDetails}>
                    Delete Article
                </Button>

                {loading && <Spin style={{ marginLeft: '10px' }} />}
            </Card>

            {articleDetails && (
                <Card style={{ margin: '50px' }}>
                    <h2>{articleDetails.title}</h2>

                    {/* {articleDetails.image &&
                       ( <Image
                            width={300}
                            alt="Article"
                            src={articleDetails.image}
                            className='article-image'
                        />)
                    } */}

                    <p>{articleDetails.description}</p>
                </Card>
            )}
        </center>
    );
};

export default ManageArticles;