import React from 'react'
import Headlines from './Headlines'
import { Col, Divider, Layout, Row } from 'antd'
import Articles from './Articles'
import Trends from './Trends'
import { Helmet } from 'react-helmet'
import Logo from './logo.png'

const { Footer } = Layout;
const Home: React.FC = () => {
    return (
        <div>
            <Helmet>
                <title>Home - Dynamiqtec</title>
                <meta name="description" content="" />
                <meta property="og:title" content="Dynamiqtec" />
                <meta property="og:image" content={Logo} />
            </Helmet>
            <h1 className='my-title mt-2 mb-5 mx-auto'>The Latest Buzz: Tech, Games, and Crypto News</h1>
            <Headlines />
            <Divider />
            <Row gutter={16}>
                <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                    <Articles />
                </Col>
                <Col xs={0} sm={0} md={6} lg={8} xl={8} className='borderh'>
                    <Trends />

                    <Footer className='footer'>
                        Dynamiqtec ©{new Date().getFullYear()} All right reserved
                    </Footer>
                </Col>
            </Row>
        </div>
    )
}

export default Home