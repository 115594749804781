import { Layout } from 'antd';
import React from 'react'
import { Routes, Route, Outlet } from "react-router-dom";
import Home from './Home';
import HighTech from './HighTech';
import Gaming from './Gaming';
import Crypto from './Crypto';
import ProductsReviews from './ProductsReviews/ProductsReviews';
import A404 from './404';
import ArticlePage from './ArticlePage';
import Search from './Search';
import GuideComparisons from './GuideComparisons/GuideComparisons';
import ProductReviewPage from './ProductsReviews/ProductReviewPage';
import GuideComparisonsPage from './GuideComparisons/GuideComparisonsPage';
import Unsubscribe from './Unsubscribe';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import PrivacyPolicy from './PrivacyPolicy';
import InfoCenter from './InfoCenter';

const { Content } = Layout;
const MainContent: React.FC = () => {
    return (
        <Content className='content' >
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/high-tech" element={<HighTech />} />
                <Route path="/gaming" element={<Gaming />} />
                <Route path="/crypto" element={<Crypto />} />
                <Route path="/products-reviews" element={<ProductsReviews />} />
                <Route path="/product-review/:slug" element={<ProductReviewPage />} />
                <Route path="/guides-comparisons" element={<GuideComparisons />} />
                <Route path="/guides/:slug" element={<GuideComparisonsPage />} />
                <Route path="/*" element={<A404 />} />
                <Route path="/article/:id" element={<ArticlePage />} />
                <Route path="/search" element={<Search />} />
                <Route path="/unsubscribe" element={<Unsubscribe />} />
                <Route path="/about-us" element={<AboutUs />} />
                <Route path="/contact-us" element={<ContactUs />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/info-center" element={<InfoCenter />} />

            </Routes>

            <Outlet />
        </Content>
    )
}

export default MainContent