import React, { useState } from 'react';
import { Form, Input, Button, notification, Typography, Layout } from 'antd';
import { AxiosInstance } from './AxiosInstance';
import Logo from './logo1.png'
import BackButton from './BackButton';

const { TextArea } = Input;
const { Title, } = Typography;
const { Content } = Layout;

const ContactUs: React.FC = () => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();

    const onFinish = async (values: any) => {
        setLoading(true);
        try {
            await AxiosInstance.post('/contact-messages/', values);
            notification.success({
                message: 'Message Sent',
                description: 'Your message has been sent successfully.',
            });
            form.resetFields()
        } catch (error) {
            console.error('Error sending message:', error);
            notification.error({
                message: 'Message Failed',
                description: 'There was an error sending your message. Please try again.',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Layout style={{ padding: '20px', background: '#fff' }}>
            <BackButton />
            <Content style={{ maxWidth: '1000px', margin: '0 auto' }}>
                <img
                    src={Logo}
                    alt="Dynamiqtec Logo"
                    className='big-logo'
                />
                <Title level={2}>Contact Us</Title>
                <Form
                    name="contact"
                    onFinish={onFinish}
                    layout="vertical"
                    form={form}
                >
                    <Form.Item
                        name="name"
                        label="Name"
                        rules={[{ required: true, message: 'Please enter your name' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                            { required: true, type: 'email', message: 'Please enter a valid email address' },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="subject"
                        label="Subject"
                        rules={[{ required: true, message: 'Please enter a subject' }]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="message"
                        label="Message"
                        rules={[{ required: true, message: 'Please enter your message' }]}
                    >
                        <TextArea rows={4} />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            Send Message
                        </Button>
                    </Form.Item>
                </Form>
            </Content>
        </Layout>
    );
};

export default ContactUs;