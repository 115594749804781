import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Card, Col, Row, Typography, Spin, Tooltip } from 'antd';
import { ProductAxiosInstance } from '../AxiosInstance';
import { useNavigate } from 'react-router-dom';
import { FaSpinner } from 'react-icons/fa6';

const { Title } = Typography;

interface Product {
    id: number;
    name: string;
    description: string;
    image: string | null;
    slug: string;
}

interface CategoryFolder {
    id: number;
    name: string;
    products: Product[];
}

interface Category {
    id: number;
    name: string;
    category_folders: CategoryFolder[];
}

const ProductsReviews: React.FC = () => {
    const [categories, setCategories] = useState<Category[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchReviews = async () => {
            try {
                const response = await ProductAxiosInstance.get('/classified-products/');
                setCategories(response.data);
            } catch (error) {
                console.error('Error fetching reviews:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchReviews();
    }, []);

    const handleProductClick = (slug: string) => {
        navigate(`/product-review/${slug}`);
    };

    if (loading) {
        return <center><Spin indicator={<FaSpinner className="spinner" />} spinning={loading} /></center>;
    }

    return (
        <div>
            <Helmet>
                <title>High-Tech Products Reviews - Dynamiqtec</title>
                <meta name="description" content="High-Tech products reviews on Dynamiqtec" />
                <link rel="canonical" href="https://www.dynamiqtec.com/products_reviews/" />
            </Helmet>

            <h1 className="my-title mt-2 mb-5 mx-auto">High-Tech Products Reviews</h1>

            {
                categories.map((category) => (
                    <div key={category.id} style={{ marginBottom: '40px' }}>
                        <Title className='category-name' level={2}>{category.name}</Title>
                        <Row gutter={[16, 16]}>
                            {category.category_folders.map((folder) => (
                                <Col key={folder.id} xs={24} sm={12} md={8} lg={6}>
                                    <Card
                                        hoverable
                                        title={folder.name}
                                    >
                                        <ul className='product-ul'>
                                            {folder.products.map((product) => (
                                                <Tooltip title={`${product.name} reviews`}>
                                                    <li className='product-li' key={product.id} onClick={() => handleProductClick(product.slug)}>{product.name}</li>
                                                </Tooltip>
                                            ))}
                                        </ul>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </div>
                ))
            }
        </div>
    );
};

export default ProductsReviews;
