import React, { useState } from 'react';
import { RiLogoutCircleLine } from "react-icons/ri";
import { Button, Tooltip, Modal } from 'antd';
import { useNavigate } from 'react-router-dom';

const Logout: React.FC = () => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const navigate = useNavigate();

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
        localStorage.removeItem('admin')
        localStorage.removeItem('admin-name')
        navigate('/admin/login');
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    return (
        <div style={{ position: 'fixed', top: 16, right: 16 }}>
            <Tooltip title="Logout">
                <Button icon={<RiLogoutCircleLine />} onClick={showModal} />
            </Tooltip>
            <Modal
                title="Confirm Logout"
                open={isModalVisible}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="Yes"
                cancelText="No"
            >
                <p>Are you sure you want to logout?</p>
            </Modal>
        </div>
    );
}

export default Logout;
