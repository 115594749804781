import React, { useState } from 'react';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
} from '@ant-design/icons';
import { Layout, Button } from 'antd';
import SideMenu from './SideMenu';
import TopMenu from './TopMenu';
import MainContent from './MainContent';

const { Sider } = Layout;

const Main: React.FC = () => {
    const [collapsed, setCollapsed] = useState(true);


    return (
        <Layout hasSider>
            <Sider
                theme='light'
                className='bgLight'
                trigger={null}
                collapsible
                collapsed={collapsed}
                breakpoint="lg"
                collapsedWidth="60"
                onCollapse={(collapsed, type) => {
                    setCollapsed(collapsed);
                }}
                style={{ overflowX: 'hidden', overflowY: 'auto', height: '100vh', position: 'fixed', left: 0, top: 0, bottom: 0 }}
            >
                {/* <div className="demo-logo-vertical" /> */}
                <Button
                    type="text"
                    icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    onClick={() => setCollapsed(!collapsed)}
                    style={{
                        fontSize: '16px',
                        width: 64,
                        height: 64,
                    }}
                />
                <SideMenu />
            </Sider>
            <Layout style={{ marginLeft: collapsed ? 45 : 200 }}>
                <TopMenu />
                <MainContent />
            </Layout>
        </Layout>
    );
};

export default Main;