import React from 'react'
import { Button, Card, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';

type FieldType = {
    username?: string;
    password?: string;
};


const Login: React.FC = () => {
    const navigate = useNavigate()

    const onFinish = (values: any) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    return (
        <Card title="Login" className='mx-auto mt-5' style={{ width: 350 }}>
            <Form
                name="login"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item<FieldType>
                    label="Username"
                    name="username"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<FieldType>
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
            <p>New here ? <Button onClick={() => navigate('/signup')} type='link'>Signup</Button></p>
        </Card>
    )
}

export default Login