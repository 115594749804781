import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Row, Col, Typography, Spin, Tooltip, Card } from 'antd';
import { ProductAxiosInstance } from '../AxiosInstance';
import { FaSpinner } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';

const { Title } = Typography;

interface Guide {
  id: number;
  title: string;
  slug: string;
  content: string;
  generated_at: string;
}

interface Topic {
  id: number;
  name: string;
  details: string;
  image: string;
  last_review_update: string;
  update_review: boolean;
  guides: Guide[];
}

interface Subcategory {
  id: number;
  name: string;
  topics: Topic[];
}

interface Category {
  id: number;
  name: string;
  subcategories: Subcategory[];
}

const GuideComparisons: React.FC = () => {
  const [categories, setCategories] = useState<Category[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate()

  useEffect(() => {
    const fetchGuides = async () => {
      try {
        const response = await ProductAxiosInstance.get('/classified-guides/');
        setCategories(response.data);
      } catch (error) {
        console.error('Error fetching guides:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchGuides();
  }, []);

  if (loading) {
    return <center><Spin indicator={<FaSpinner className="spinner" />} spinning={loading} /></center>;
  }

  const handleProductClick = (slug: string) => {
    navigate(`/guides/${slug}`);
  };

  return (
    <div>
      <Helmet>
        <title>Guides and Comparisons - Dynamiqtec</title>
        <meta name="description" content="Guides and comparisons on Dynamiqtec" />
        <link rel="canonical" href="https://www.dynamiqtec.com/guides-comparisons/" />
      </Helmet>

      <h1 className="my-title mt-2 mb-5 mx-auto">High-Tech Products Guides and Comparisons</h1>

      {
        categories.map((category) => (
          <div key={category.id} style={{ marginBottom: '40px' }}>
            <Title className='category-name' level={2}>{category.name}</Title>
            <Row gutter={[16, 16]}>
              {category.subcategories.map((subcategory) => (
                <Col key={subcategory.id} xs={24} sm={12} md={8} lg={6}>
                  <Card
                    hoverable
                    title={subcategory.name}
                  >
                    <ul className='product-ul'>
                      {subcategory.topics.map((topic: Topic) => (
                        <Tooltip title={`${topic.details}`}>
                          <li className='product-li' key={topic.id} onClick={() => handleProductClick(topic.guides[0].slug)}>{topic.name}</li>
                        </Tooltip>
                      ))}
                    </ul>
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        ))
      }
    </div>
  );
};

export default GuideComparisons;
