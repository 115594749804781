import React from 'react'
import { Button, Card, Form, Input } from 'antd';
import { useNavigate } from 'react-router-dom';

type FieldType = {
    username?: string;
    password?: string;
    remember?: string;
    first_name?: string;
    last_name?: string;
    email?: string;
    confirm_password?: string;
};


const Register: React.FC = () => {
    const navigate = useNavigate()

    const onFinish = (values: any) => {
        console.log('Success:', values);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };



    return (
        <Card title="Register" className='mx-auto mt-5' style={{ width: 400 }}>
            <Form
                name="login"
                initialValues={{ remember: true }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item<FieldType>
                    label="First name"
                    name="first_name"
                    rules={[{ required: true, message: 'Please input your first name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item<FieldType>
                    label="Last name"
                    name="last_name"
                    rules={[{ required: true, message: 'Please input your last name!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item<FieldType>
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: 'Please input your email!' },
                    { type: 'email', message: 'Email is not valid!' }]}
                >
                    <Input />
                </Form.Item>
                <Form.Item<FieldType>
                    label="Username"
                    name="username"
                    rules={[{ required: true, message: 'Please input your username!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item<FieldType>
                    label="Password"
                    name="password"
                    rules={[{ required: true, message: 'Please input your password!' },
                    {
                        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&]).{8,}$/,
                        message: "Must contain the following: one upper case letter, one lowercase letter, one number and one special character!"
                    }]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item<FieldType>
                    label="ComfirmPassword"
                    name="confirm_password"
                    rules={[{ required: true, message: 'Please input confirm yout password!' },
                    {
                        pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&]).{8,}$/,
                        message: "Must contain the following: one upper case letter, one lowercase letter, one number and one special character!"
                    },
                        // {
                        //     validator: checkPasswordConfirm
                        // }
                    ]}
                >
                    <Input.Password />
                </Form.Item>

                <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
            <p>Already have an account ? <Button onClick={() => navigate('/login')} type='link'>Login</Button></p>
        </Card>
    )
}

export default Register