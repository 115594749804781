import React from 'react'
import { Helmet } from 'react-helmet'
import Logo from './logo.png'
import { Divider, Row, Col } from 'antd'
import { Footer } from 'antd/es/layout/layout'
import Trends from './Trends'
import CategoryArticles from './CategoryArticles'

const HighTech: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>High-Tech - Dynamiqtec</title>
                <meta name="description" content="" />
                <meta property="og:title" content="High Tech Dynamiqtec" />
                <meta property="og:image" content={Logo} />
            </Helmet>
            <h1 className='my-title'>World Tech News</h1>
            <Divider />
            <Row gutter={16}>
                <Col xs={24} sm={24} md={16} lg={16} xl={16}>
                    <CategoryArticles category="technology" />
                </Col>
                <Col xs={0} sm={0} md={6} lg={8} xl={8} className='borderh'>
                    <Trends />

                    <Footer className='footer'>
                        Dynamiqtec ©{new Date().getFullYear()} All right reserved
                    </Footer>
                </Col>
            </Row>
        </>
    )
}

export default HighTech