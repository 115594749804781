import React from 'react';
import { Form, Input, Button, notification } from 'antd';
import { NewsletterAxiosInstance } from './AxiosInstance';
import { useNavigate } from 'react-router-dom';

const Unsubscribe: React.FC = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();

    const handleUnsubscribe = async (values: { email: any; }) => {
        try {
            await NewsletterAxiosInstance.post('/subscribers/unsubscribe/', { email: values.email });
            notification.success({
                message: 'Unsubscribed Successfully',
                description: 'You have been unsubscribed from our newsletter.',
            });
            form.resetFields();
            setTimeout(() => {
                navigate('/');
            }, 2000);
        } catch (error) {
            notification.error({
                message: 'Unsubscribe Failed',
                description: 'There was an error unsubscribing. Please try again later.',
            });
        }
    };

    return (
        <div style={{ maxWidth: 400, margin: '0 auto', padding: '2rem' }}>
            <h2>Unsubscribe from Newsletter</h2>
            <p>If you no longer wish to receive our newsletter, please enter your email address below to unsubscribe.</p>
            <Form form={form} onFinish={handleUnsubscribe}>
                <Form.Item
                    name="email"
                    rules={[{ required: true, type: 'email', message: 'Please enter a valid email address!' }]}
                >
                    <Input placeholder="Enter your email" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Unsubscribe</Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default Unsubscribe;
