// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Input, Layout, Menu } from 'antd';
import React from 'react'
import Logo from './logo.png'
import { useNavigate } from 'react-router-dom';


const { Header } = Layout;
const { Search } = Input;


const TopMenu: React.FC = () => {
    const navigate = useNavigate()

    const handleSearch = (value: string) => {
        navigate(`/search?q=${encodeURIComponent(value)}`)
    }

    return (
        <Header className='bgLight' style={{ display: 'flex', alignItems: 'center' }}>
            <img
                src={Logo}
                alt="Dynamiqtec Logo"
                className='logo'
                onClick={() => navigate('/')}
            />
            <Search
                className='search menu'
                placeholder="Search"
                allowClear
                onSearch={handleSearch}
            />
            {/* <Menu mode="horizontal" className='bgLight menu'>
                <Menu.Item key="1">Element 1</Menu.Item>
                <Menu.Item key="2">Element 2</Menu.Item>
                <Menu.Item key="3">Element 3</Menu.Item>
            </Menu> */}
        </Header>
    )
}

export default TopMenu