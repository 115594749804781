import React from 'react';
import { Card, Typography, Layout, Button } from 'antd';
import { Link } from 'react-router-dom';
import Logo from './logo1.png'

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const InfoCenter: React.FC = () => {
    return (
        <Layout style={{ padding: '20px', background: '#fff' }}>
            <Content style={{ maxWidth: '800px', margin: '0 auto' }}>
                <img
                    src={Logo}
                    alt="Dynamiqtec Logo"
                    className='big-logo'
                />
                <Title level={2}>Information Center</Title>
                <Card style={{ marginBottom: '20px' }}>
                    <Title level={4}>About Us</Title>
                    <Paragraph>
                        Learn more about Dynamiqtec, our mission, and the people behind it.
                    </Paragraph>
                    <Link to="/about-us">
                        <Button type="primary">Go to About Us</Button>
                    </Link>
                </Card>

                <Card style={{ marginBottom: '20px' }}>
                    <Title level={4}>Contact Us</Title>
                    <Paragraph>
                        Get in touch with us for any queries or support.
                    </Paragraph>
                    <Link to="/contact-us">
                        <Button type="primary">Go to Contact Us</Button>
                    </Link>
                </Card>

                <Card style={{ marginBottom: '20px' }}>
                    <Title level={4}>Privacy Policy</Title>
                    <Paragraph>
                        Understand how we handle your data and protect your privacy.
                    </Paragraph>
                    <Link to="/privacy-policy">
                        <Button type="primary">Go to Privacy Policy</Button>
                    </Link>
                </Card>
            </Content>
        </Layout>
    );
};

export default InfoCenter;