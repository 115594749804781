import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Col, Row, Spin, Image } from 'antd';
import { ProductAxiosInstance } from '../AxiosInstance';
import { FaSpinner } from 'react-icons/fa6';
import ReactMarkdown from 'react-markdown';
import BackButton from '../BackButton';

interface Category {
    id: number;
    name: string;
}

interface CategoryFolder {
    id: number;
    name: string;
    category: Category;
}

interface Product {
    id: number;
    name: string;
    description: string;
    image: string | null;
    slug: string;
    category: Category[];
    category_folder: CategoryFolder[];
    last_review_update: string | null;
    update_review: boolean;
}

interface Review {
    id: number;
    title: string;
    content: string;
    rating: number;
    created_at: string;
    slug: string;
    product: Product;
}

const ProductReviewPage: React.FC = () => {
    const { slug } = useParams<{ slug: string }>();
    const [review, setReview] = useState<Review | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchReview = async () => {
            try {
                const response = await ProductAxiosInstance.get(`/product-review/${slug}/`);
                setReview(response.data[0]);
            } catch (error) {
                console.error('Error fetching review:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchReview();
    }, [slug]);

    if (loading) {
        return <center><Spin indicator={<FaSpinner className="spinner" />} spinning={loading} /></center>;
    }

    if (!review) {
        return <div>Review not found</div>;
    }
    const isSmallScreen = window.innerWidth <= 768;

    const imageWidth = isSmallScreen ? '100%' : 400;
    return (
        <div>
            <Helmet>
                <title>{review.title} - Dynamiqtec</title>
                <meta name="description" content={`Reviews and details for ${review.product.name}`} />
                <link rel="canonical" href={`https://www.dynamiqtec.com/product-review/${slug}`} />
            </Helmet>
            <BackButton />
            <h1 className="my-title mt-2 mb-5 mx-auto">{review.product.name}</h1>
            <center>
                {review.product.image &&
                    <Image
                        width={imageWidth}
                        alt={review.product.name}
                        src={`https://backend.dynamiqtec.com${review.product.image}`}
                        className='article-image'
                    />
                }
            </center>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <ReactMarkdown className='review-content'>{review.content}</ReactMarkdown>
                </Col>
            </Row>
        </div>
    );
};

export default ProductReviewPage;
