import React from 'react';
import { Card, Typography, Layout } from 'antd';
import Logo from './logo1.png'
import BackButton from './BackButton';

const { Title, Paragraph } = Typography;
const { Content } = Layout;

const AboutUs: React.FC = () => {
    return (
        <Layout style={{ padding: '20px', background: '#fff' }}>
            <BackButton/>
            <Content style={{ maxWidth: '800px', margin: '0 auto' }}>
                <img
                    src={Logo}
                    alt="Dynamiqtec Logo"
                    className='big-logo'
                />
                <Title level={2}>About Us</Title>
                <Paragraph>
                    Welcome to Dynamiqtec! We are dedicated to bringing you the latest news in technology, gaming, and cryptocurrency, along with in-depth product reviews and comprehensive guide comparisons of tech products.
                </Paragraph>
                <Title level={3}>Our Mission</Title>
                <Paragraph>
                    At Dynamiqtec, our mission is to provide our readers with reliable and up-to-date information from various online sources. We aim to help you stay informed about the latest trends and developments in the tech world and make informed decisions when purchasing tech products.
                </Paragraph>
                <Title level={3}>Our Team</Title>
                <Card>
                    <Title level={4}>Jean-Eudes ASSOGBA</Title>
                    <Paragraph>FullStack Developer and CEO</Paragraph>
                    <Paragraph>
                        Jean-Eudes is a passionate developer with a strong background in both front-end and back-end development. As the CEO of Dynamiqtec, he leads the team with a vision to innovate and deliver high-quality content.
                    </Paragraph>
                </Card><br /><br />
                <Card>
                    <Title level={4}>Bernard ASSOGBA</Title>
                    <Paragraph>Backend Developer</Paragraph>
                    <Paragraph>
                        Bernard specializes in backend development, ensuring that our website runs smoothly and efficiently. He is committed to providing a seamless experience for our users.
                    </Paragraph>
                </Card><br /><br />
                <Card>
                    <Title level={4}>ODJARADO KEBO Mohamed</Title>
                    <Paragraph>Head of Redaction</Paragraph>
                    <Paragraph>
                        Mohamed oversees the content creation process at Dynamiqtec. His expertise in tech writing ensures that our articles are both informative and engaging.
                    </Paragraph>
                </Card><br /><br />
            </Content>
        </Layout>
    );
};

export default AboutUs;