import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import Article from './Article';
import { AxiosInstance } from '../component/AxiosInstance';
import A404 from './404';
import BackButton from './BackButton';
import { FaSpinner } from 'react-icons/fa6';
import { Spin } from 'antd';

type ArticleType = {
    id: string;
    title: string;
    image?: string;
    content: string;
    // author: string;
    created_at: string;
    like_count: string;
    dislike_count: string;
    share_count: string;
    source: string;
    source_link: string;
    tags: any[];
    slug: string;
    dynamiqtec1: boolean;
    dynamiqtec2: boolean;
    dynslug: string;
}

const ArticlePage: React.FC = () => {
    const [data, setData] = useState<ArticleType | null>(null);
    const [loading, setLoading] = useState(true);
    const idFromURL = window.location.href.split('/').pop();

    useEffect(() => {
        const fetchArticle = async () => {
            setLoading(true);
            try {
                const response = await AxiosInstance.get(`/articles/${idFromURL}/retrieve_by_slug/`);
                if (response.status === 200) {
                    setData(response.data);
                    console.log(response)
                } else {
                    console.log(response.status);
                }
            } catch (error) {
                console.error('Error: ', error);
            } finally {
                setLoading(false);
            }
        };

        fetchArticle();


        return () => {

        };
    }, [idFromURL]);

    if (loading) {
        return <center><Spin indicator={<FaSpinner className="spinner" />} spinning={loading} /></center>;
    }

    if (!data) {
        return <A404 />;
    }

    const { id, title, image, content, like_count, dislike_count, share_count, source, source_link, tags, created_at, dynamiqtec1, dynamiqtec2, dynslug } = data;

    const firstParagraph = content.split('\n\n')[0];

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>{title}</title>
                <meta name="description" content={firstParagraph ? firstParagraph : content} />
                <meta property="og:title" content={title} />
                <meta property="og:image" content={`https://backend.dynamiqtec.com${image}` || 'default-image-url'} />
                <meta property="og:type" content="article" />
            </Helmet>

            <BackButton />
            <Article
                id={id}
                title={title}
                image={`https://backend.dynamiqtec.com${image}`}
                content={content}
                likes={like_count}
                dislikes={dislike_count}
                share={share_count}
                source={source}
                source_link={source_link}
                tags={tags}
                slug={''}
                date={created_at}
                dynamiqtec1={dynamiqtec1}
                dynamiqtec2={dynamiqtec2}
                dynslug={dynslug}
            />
        </>
    );
};

export default ArticlePage;