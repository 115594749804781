import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { Col, Row, Spin, Image } from 'antd';
import { ProductAxiosInstance } from '../AxiosInstance';
import { FaSpinner } from 'react-icons/fa6';
import ReactMarkdown from 'react-markdown';
import BackButton from '../BackButton';

interface Guide {
    id: number;
    content: string;
    title: string;
    slug: string;
    image: string;
    details: string;
}

const GuideComparisonsPage: React.FC = () => {
    const { slug } = useParams<{ slug: string }>();
    const [guide, setGuide] = useState<Guide | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchReview = async () => {
            try {
                const response = await ProductAxiosInstance.get(`/guides/${slug}/`);
                console.log(response.data)
                setGuide(response.data)
            } catch (error) {
                console.error('Error fetching review:', error);
            } finally {
                setLoading(false);
            }
        };

        fetchReview();
    }, [slug]);

    if (loading) {
        return <center><Spin indicator={<FaSpinner className="spinner" />} spinning={loading} /></center>;
    }

    if (!guide) {
        return <div>File not found</div>;
    }
    const isSmallScreen = window.innerWidth <= 768;

    const imageWidth = isSmallScreen ? '100%' : 400;
    return (
        <div>
            <Helmet>
                <title>{`${guide.details}`} - Dynamiqtec</title>
                <meta name="description" content={`${guide.details}`} />
                <link rel="canonical" href={`https://www.dynamiqtec.com/guide/${slug}`} />
            </Helmet>
            <BackButton />
            <h1 className="my-title mt-2 mb-5 mx-auto">{guide.details}</h1>

            <center>
                {guide.image &&
                    <Image
                        width={imageWidth}
                        alt={guide.details}
                        src={`https://backend.dynamiqtec.com/media/${guide.image}`}
                        className='article-image'
                    />
                }
            </center>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <ReactMarkdown className='review-content'>{guide.content}</ReactMarkdown>
                </Col>
            </Row>
        </div>
    );
};

export default GuideComparisonsPage;
