import React from 'react'

const CryptoTicker: React.FC = () => {
    return (
        <div>
            <div className="cryptohopper-web-widget" data-id="2" data-price_format="3" data-realtime="on" data-atid="33871"></div>
        </div>
    )
}

export default CryptoTicker