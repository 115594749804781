import { Button, Card, List, Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import { AxiosInstance } from '../component/AxiosInstance';
import { useNavigate } from 'react-router-dom';

const Trends: React.FC = () => {

    const [data, setData] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate()

    useEffect(() => {
        const fetchArticles = async () => {
            setLoading(true);
            try {
                const response = await AxiosInstance.get('/trends/');
                if (response.status === 200) {
                    setData(response.data[0]);
                    console.log(response.data)
                }
            } catch (error) {
                console.error('Error: ', error);
            } finally {
                setLoading(false);
            }
        };
        fetchArticles();
    }, []);


    const handleTrendClick = (value: string) => {
        navigate(`/search?q=${encodeURIComponent(value)}`)
    }


    return (
        <div>
            <Card title="Trending" bordered={false}>
                {loading ? <Skeleton active /> : (
                    <List
                        dataSource={data}
                        renderItem={(item, index) => (
                            <List.Item key={index} style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Button
                                    type='link'
                                    className='capitalize'
                                    onClick={() => handleTrendClick(item.tags__name)}>
                                    {item.tags__name}
                                </Button>
                                <span>{item.tag_count}</span>
                            </List.Item>
                        )}
                    />)
                }
            </Card>
        </div>
    );
};

export default Trends;
