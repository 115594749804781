import React, { useState, useEffect } from 'react';
import { Card, Button, Table, Typography, Modal, Form, Input, notification } from 'antd';
import moment from 'moment';
import { NewsletterAxiosInstance } from '../AxiosInstance'; // Import your Axios instance

const { Title } = Typography;

interface Subscriber {
    id: number;
    email: string;
}

interface EmailLog {
    id: number;
    email: string;
    status: string;
    response: string;
    sent_at: string; // Assuming sent_at is a string date format
}

interface Newsletter {
    id: number;
    content: string;
    created_at: string; // Assuming created_at is a string date format
}

const ManageNewsletter: React.FC = () => {
    const [subscribers, setSubscribers] = useState<Subscriber[]>([]);
    const [newsletterLogs, setNewsletterLogs] = useState<EmailLog[]>([]);
    const [newsletters, setNewsletters] = useState<Newsletter[]>([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        fetchSubscribers();
        fetchRecentNewsletterLogs();
        fetchNewsletters();
    }, []);

    const fetchSubscribers = async () => {
        try {
            const response = await NewsletterAxiosInstance.get<Subscriber[]>('/subscribers/');
            setSubscribers(response.data);
        } catch (error) {
            console.error('Error fetching subscribers:', error);
        }
    };

    const fetchRecentNewsletterLogs = async () => {
        try {
            const response = await NewsletterAxiosInstance.get('/email-logs/');
            setNewsletterLogs(response.data.results);
        } catch (error) {
            console.error('Error fetching newsletter logs:', error);
        }
    };

    const fetchNewsletters = async () => {
        try {
            const response = await NewsletterAxiosInstance.get<Newsletter[]>('/newsletters/');
            setNewsletters(response.data);
        } catch (error) {
            console.error('Error fetching newsletters:', error);
        }
    };

    const handleCreateSubscriber = async (values: { email: string }) => {
        try {
            await NewsletterAxiosInstance.post('/subscribers/', values);
            notification.success({
                message: 'Subscriber Created',
                description: 'New subscriber added successfully.',
            });
            setModalVisible(false);
            form.resetFields();
            fetchSubscribers();
        } catch (error) {
            console.error('Error creating subscriber:', error);
            notification.error({
                message: 'Failed to Create Subscriber',
                description: 'An error occurred while creating subscriber.',
            });
        }
    };

    const handleDeleteSubscriber = async (email: string) => {
        try {
            await NewsletterAxiosInstance.post(`/subscribers/unsubscribe/`, { email });
            notification.success({
                message: 'Subscriber Deleted',
                description: 'Subscriber deleted successfully.',
            });
            fetchSubscribers();
        } catch (error) {
            console.error('Error deleting subscriber:', error);
            notification.error({
                message: 'Failed to Delete Subscriber',
                description: 'An error occurred while deleting subscriber.',
            });
        }
    };

    const columns = [
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Actions',
            dataIndex: '',
            key: 'actions',
            render: (_: any, record: Subscriber) => (
                <Button type="link" danger onClick={() => handleDeleteSubscriber(record.email)}>
                    Delete
                </Button>
            ),
        },
    ];

    return (
        <div style={{ padding: '20px' }}>
            <h1 className="my-title">Newsletter Management</h1>

            <Card style={{ marginBottom: '20px' }}>
                <Title level={4}>Subscribers</Title>
                <Button type="primary" onClick={() => setModalVisible(true)} style={{ marginBottom: '10px' }}>
                    Add Subscriber
                </Button>
                <Table
                    dataSource={subscribers}
                    columns={columns}
                    rowKey="id"
                    pagination={{ pageSize: 10 }}
                    summary={() => (
                        <Table.Summary.Row>
                            <Table.Summary.Cell index={0}>Total Subscribers: {subscribers.length}</Table.Summary.Cell>
                        </Table.Summary.Row>
                    )}
                />
            </Card>

            <Card style={{ marginBottom: '20px' }}>
                <Title level={4}>Recent Email Logs</Title>
                <Button
                    type="primary"
                    onClick={() => fetchRecentNewsletterLogs()} // Reload recent logs
                    style={{ marginBottom: '10px' }}
                >
                    Refresh Logs
                </Button>
                <div>
                    {newsletterLogs.map((log) => (
                        <div key={log.id} style={{ marginBottom: '10px' }}>
                            <p>
                                <strong>Email:</strong> {log.email}
                            </p>
                            <p>
                                <strong>Status:</strong> {log.status}
                            </p>
                            <p>
                                <strong>Sent At:</strong> {moment(log.sent_at).format('LLL')}
                            </p>
                            <p>
                                <strong>Response:</strong> {log.response}
                            </p>
                            <hr />
                        </div>
                    ))}
                </div>
            </Card>

            <Card>
                <Title level={4}>Newsletters</Title>
                <div>
                    {newsletters.map((newsletter) => (
                        <div key={newsletter.id} style={{ marginBottom: '20px' }}>
                            <p>
                                <strong>Created At:</strong> {moment(newsletter.created_at).format('LLL')}
                            </p>
                            <div dangerouslySetInnerHTML={{ __html: newsletter.content }} />
                            <hr />
                        </div>
                    ))}
                </div>
            </Card>

            {/* Modal for adding subscriber */}
            <Modal title="Add Subscriber" open={modalVisible} onCancel={() => setModalVisible(false)} footer={null}>
                <Form form={form} onFinish={handleCreateSubscriber}>
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                            { required: true, type: 'email', message: 'Please enter a valid email address!' },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            Add Subscriber
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
        </div>
    );
};

export default ManageNewsletter;