import { Menu } from 'antd';
import React, { useState, useEffect } from 'react';
import { IoHome, IoGameController } from 'react-icons/io5';
import { GiTechnoHeart } from 'react-icons/gi';
import { FaBitcoin, FaEthereum, FaInfo } from 'react-icons/fa6';
import { SiTether } from 'react-icons/si';
import { MdProductionQuantityLimits, MdRateReview } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';

const SideMenu: React.FC = () => {
    const [cryptoIcon, setCryptoIcon] = useState(<FaBitcoin className='left-icons' />);
    const navigate = useNavigate();

    useEffect(() => {
        const interval = setInterval(() => {
            setCryptoIcon((prevIcon) => {
                switch (prevIcon.type) {
                    case FaBitcoin:
                        return <SiTether className='left-icons' />;
                    case SiTether:
                        return <FaEthereum className='left-icons' />;
                    case FaEthereum:
                        return <FaBitcoin className='left-icons' />;
                    default:
                        return <FaBitcoin className='left-icons' />;
                }
            });
        }, 5000);
        // 180000

        return () => clearInterval(interval);

    }, []);

    const handleMenuClick = (key: string) => {
        switch (key) {
            case '1':
                navigate('/');
                break;
            case '2':
                navigate('/high-tech');
                break;
            case '3':
                navigate('/gaming');
                break;
            case '4':
                navigate('/crypto');
                break;
            case '5':
                navigate('/products-reviews');
                break;
            case '6':
                navigate('/guides-comparisons');
                break;
            case '7':
                navigate('/info-center');
                break;
            default:
                break;
        }
    };

    return (
        <Menu
            className='bgLight'
            mode='inline'
            defaultSelectedKeys={['1']}
            onClick={({ key }) => handleMenuClick(key)}
            items={[
                {
                    key: '1',
                    icon: <IoHome className='left-icons' />,
                    label: 'Home',
                },
                {
                    key: '2',
                    icon: <GiTechnoHeart className='left-icons' />,
                    label: 'High Tech',
                },
                {
                    key: '3',
                    icon: <IoGameController className='left-icons' />,
                    label: 'Gaming',
                },
                {
                    key: '4',
                    icon: cryptoIcon,
                    label: 'Crypto',
                },
                {
                    key: '5',
                    icon: <MdRateReview className='left-icons' />,
                    label: 'Products Reviews',
                },
                {
                    key: '6',
                    icon: <MdProductionQuantityLimits className='left-icons' />,
                    label: 'Guides Comparisons',
                },
                {
                    key: '7',
                    icon: <FaInfo className='left-icons' />,
                    label: 'Info Center',
                },
            ]}
        />
    );
};

export default SideMenu;