import React, { useEffect, useState } from 'react';
import { Modal, Button, Input, Form, notification } from 'antd';
import Cookies from 'js-cookie';
import { NewsletterAxiosInstance } from './AxiosInstance';

const Subscribe: React.FC = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        const hasSeenSubscriptionPrompt = Cookies.get('hasSeenSubscriptionPrompt');
        if (!hasSeenSubscriptionPrompt) {
            const timer = setTimeout(() => {
                setIsVisible(true);
            }, 180000); // 2 minutes
            return () => clearTimeout(timer);
        }
    }, []);

    const handleSubscribe = async (values: any) => {
        try {
            await NewsletterAxiosInstance.post('/subscribers/', values);
            notification.success({
                message: 'Subscription Successful',
                description: 'You have successfully subscribed to our newsletter!',
            });
            setIsVisible(false);
            Cookies.set('hasSeenSubscriptionPrompt', 'true', { expires: 365 });
        } catch (error) {
            notification.error({
                message: 'Subscription Failed',
                description: 'There was an error subscribing to the newsletter. Please try again later.',
            });
        }
    };

    const handleCancel = () => {
        setIsVisible(false);
        Cookies.set('hasSeenSubscriptionPrompt', 'true', { expires: 365 });
    };

    return (
        <Modal
            title="Subscribe to Our Newsletter"
            visible={isVisible}
            onCancel={handleCancel}
            footer={null}
        >
            <p>Stay updated with the latest tech, crypto, and gaming news. Subscribe to our daily newsletter and never miss out!</p>
            <Form form={form} onFinish={handleSubscribe}>
                <Form.Item
                    name="email"
                    rules={[{ required: true, type: 'email', message: 'Please enter a valid email address!' }]}
                >
                    <Input placeholder="Enter your email" />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Subscribe</Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default Subscribe;
