import React, { useState, useEffect, useRef } from 'react';
import { Divider, Spin } from 'antd';
import Article from './Article';
import { AxiosInstance } from '../component/AxiosInstance';
// import { FaSearch } from 'react-icons/fa';
// import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import BackButton from './BackButton';
import { FaSpinner } from "react-icons/fa";

const SearchComponent: React.FC = () => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [searchResults, setSearchResults] = useState<any[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingMore, setLoadingMore] = useState<boolean>(false);
    const [nextPage, setNextPage] = useState<string | null>(null);
    const observer = useRef<IntersectionObserver | null>(null);
    const [count, setCount] = useState('')
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const q = searchParams.get('q');

    useEffect(() => {
        if (q) {
            const decodedTerm = decodeURIComponent(q);
            setTimeout(() => {
                setSearchTerm(decodedTerm);
            }, 500);
        }
    }, [q]);

    useEffect(() => {
        const fetchSearchResults = async () => {
            try {
                setLoading(true);
                const response = await AxiosInstance.get(`/articles/search/?query=${searchTerm}`);
                setSearchResults(response.data.results);
                setNextPage(response.data.next);
                setCount(response.data.count)
            } catch (error) {
                console.error('Error', error);
            } finally {
                setLoading(false);
            }
        };

        if (searchTerm.trim() !== '') {
            fetchSearchResults();
        } else {
            setSearchResults([]);
        }
    }, [searchTerm, q]);

    // const debouncedSearch = useRef(_.debounce((term: string) => setSearchTerm(term), 50));
    const loadMoreRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const fetchSearchResults = async () => {
            try {
                setLoadingMore(true);
                const response = await AxiosInstance.get(`${nextPage}`);
                setSearchResults((prevResults) => [...prevResults, ...response.data.results]);
                setNextPage(response.data.next);
            } catch (error) {
                console.error('Error', error);
            } finally {
                setLoadingMore(false);
            }
        };
        observer.current = new IntersectionObserver(
            (entries) => {
                if (entries[0].isIntersecting && nextPage) {

                    fetchSearchResults();
                }
            },
            { threshold: 1 }
        );

        if (loadMoreRef.current) {
            observer.current.observe(loadMoreRef.current);
        }

        return () => {
            if (observer.current) {
                observer.current.disconnect();
            }
        };
    }, [nextPage]);

    return (
        <div>
            <BackButton />
            {/* <center>
                <div className="group">
                    <FaSearch className="icon" />
                    <input
                        placeholder="Search articles"
                        type="search"
                        className="input"
                        value={searchTerm}
                        onChange={(e) => debouncedSearch.current(e.target.value)}
                    />
                </div>
            </center> */}
            <Divider />

            <Spin indicator={<FaSpinner className="spinner" />} spinning={loading}>
                <div>
                    {searchResults.length === 0 && !loading && searchTerm && <div>No results found.</div>}
                    {searchResults.length !== 0 && !loading && <div>"{count}" results for: "{searchTerm}"</div>}
                    {searchResults.map((article, index) => (
                        <Article
                            key={index}
                            id={article.id}
                            title={article.title}
                            image={`https://backend.dynamiqtec.com${article.image}`}
                            content={article.description}
                            likes={article.like_count}
                            dislikes={article.dislike_count}
                            share={article.share_count}
                            source={article.source}
                            source_link={article.source_link}
                            tags={article.tags}
                            slug={article.slug}
                            date={article.created_at}
                            dynamiqtec1={article.dynamiqtec1}
                            dynamiqtec2={article.dynamiqtec2}
                            dynslug={article.dynslug}
                        />
                    ))}
                </div>
                {nextPage && <center ref={loadMoreRef}><Spin indicator={<FaSpinner className="spinner" />} spinning={loadingMore} /></center>}
            </Spin>
        </div>
    );
};

export default SearchComponent;