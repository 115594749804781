import React, { useEffect, useState } from 'react';
import {
    Card,
    Button,
    // Avatar,
    Space,
    Tag,
    Image,
    Badge
} from 'antd';
import {
    LikeOutlined,
    // DislikeOutlined,
    ShareAltOutlined,
    // UserOutlined,
} from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
// import Embed from 'react-embed';
import { v4 as uuidv4 } from 'uuid';
import { AxiosInstance } from '../component/AxiosInstance';
import toast, { Toaster } from 'react-hot-toast';

interface ArticleComponentProps {
    id: string;
    title: string;
    image?: string;
    content: string;
    // author: string;
    date: string;
    likes: string;
    dislikes: string;
    share: string;
    source: string;
    source_link: string;
    tags: any[];
    slug: string;
    dynamiqtec1: boolean;
    dynamiqtec2: boolean;
    dynslug: string;
}

const Article: React.FC<ArticleComponentProps> = ({
    id,
    title,
    image,
    content,
    // author,
    date,
    likes,
    dislikes,
    share,
    source,
    source_link,
    tags,
    slug,
    dynamiqtec1,
    dynamiqtec2,
    dynslug,
}) => {
    const navigate = useNavigate()
    const [localLikes, setLocalLikes] = useState(Number(likes));
    const [localShares, setLocalShares] = useState(Number(share));


    const isSmallScreen = window.innerWidth <= 768;

    const imageWidth = isSmallScreen ? '100%' : 400;
    // Convert the date string to a Date object
    const parsedDate = new Date(date);

    // Calculate the time ago using date-fns
    const timeAgo = formatDistanceToNow(parsedDate, { addSuffix: true });

    // Split the content into paragraphs
    const paragraphs = content.split('\n\n');

    const urlRegex = /(https?:\/\/[^\s]+)|(pic.twitter.com\/[^\s]+)/;

    const getCookie = (name: string | any[]) => {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
            const cookie = cookies[i].trim();
            if (cookie.startsWith(name + '=')) {
                return cookie.substring(name.length + 1);
            }
        }
        return null;
    };

    const [userKey, setUserKey] = useState<string | null>(getCookie('userKey'));

    const getLikedArticlesFromCookies = () => {
        const likedArticlesCookie = document.cookie.split(';').find(cookie => cookie.trim().startsWith('likedArticles='));
        if (likedArticlesCookie) {
            const likedArticles = likedArticlesCookie.split('=')[1];
            return likedArticles.split(',').map((id: string) => id.trim());
        }
        return [];
    };

    const [likedArticles, setLikedArticles] = useState<string[]>(getLikedArticlesFromCookies);
    const isArticleLiked = likedArticles.map(likedId => String(likedId).trim()).includes(String(id).trim());

    useEffect(() => {
        // If there's no user key in the cookies, generate a new one
        if (!userKey) {
            const newKey = uuidv4(); // Generate a new unique key
            setUserKey(newKey);
            // Save the new key to cookies or any other preferred storage mechanism
            document.cookie = `userKey=${newKey}; expires=${100000}; path=/`;
        }
    }, [userKey]);


    const handleLike = async () => {

        if (userKey && !likedArticles.includes(id)) {
            try {
                const data = {
                    unique_key: userKey,
                    article: id,
                };
                const response = await AxiosInstance.post(`/article-likes/`, data);
                if (response.status === 201) {
                    setLocalLikes(localLikes + 1);
                    console.log(response)
                    setLikedArticles([...likedArticles, id]);
                    document.cookie = `likedArticles=${[...likedArticles, id].join(',')}; expires=${100000}; path=/`;

                } else {
                    console.log(response.status);
                }
            } catch (error) {
                console.error('Error: ', error);
            } finally {
            }
        }
    };

    // const handleDislike = () => {
    //     // Make API request to handle dislike
    //     if (userKey) {
    //         // You need to replace this with your API endpoint and logic
    //         // Axios.post(`/api/dislike/${articleId}`, { userKey });
    //     }
    // };

    const idFromURL = window.location.href.split('/').pop();
    const handleShare = async () => {
        if (userKey) {
            try {
                const data = {
                    unique_key: userKey,
                    article: id,
                };
                if (slug === '') {
                    const articleUrl = `https://dynamiqtec.com/article/${idFromURL}`;
                    setLocalShares(localShares + 1);
                    await navigator.clipboard.writeText(articleUrl);
                    toast.success('Url copied to the clipboard')
                } else {
                    const articleUrl = `https://dynamiqtec.com/article/${slug}`;
                    setLocalShares(localShares + 1);
                    await navigator.clipboard.writeText(articleUrl);
                    toast.success('Url copied to the clipboard')
                }
                const response = await AxiosInstance.post(`/shared-articles/`, data);
                if (response.status === 201) {
                }
            } catch (error) {
                console.error('Error: ', error);
            } finally {
            }
        }
    };

    const handleTagClick = (value: string) => {
        navigate(`/search?q=${encodeURIComponent(value)}`)
    }

    const removeMarkdown = (text: string) => {
        return text.replace(/[#_*~`-]/g, ''); // Adjust the regex as needed for other markdown signs
    };


    return (
        <>
            <div>
                <Toaster
                    position="bottom-right"
                    reverseOrder={false}
                />
            </div>
            <Card bordered={false} className='article'>
                <Space className='float-end'>
                    <span>{timeAgo}</span>
                    {/* <span>{author}</span> */}
                    {/* <Avatar size="small" icon={<UserOutlined />} /> */}
                </Space><br /><br />

                <h3 className='article-title'>{removeMarkdown(title)}</h3>

                <center>
                    {image &&
                        <Image
                            width={imageWidth}
                            alt="Article"
                            src={image}
                            className='article-image'
                        />
                    }
                </center>
                <Space className='tags'>
                    <strong>Tags: </strong>
                    {tags.map((tag, index) => (
                        <Tag color='cyan' key={index} onClick={() => handleTagClick(tag.name)} className='cursor'>{tag.name}</Tag>
                    ))}
                </Space><br></br>

                <div className='article-content mt-3'>
                    {paragraphs.map((paragraph, index) => {

                        const match = paragraph.match(urlRegex);
                        const cleanedParagraph = removeMarkdown(paragraph);

                        if (match) {
                            return (
                                <p key={index} className='article-content mt-3'>
                                    {/* <Embed url={match[0]} /> */}
                                    {slug && !dynamiqtec1 && !dynamiqtec2 ?
                                        <Button type='link' onClick={() => navigate(`/article/${slug}`)}>Read more</Button> : slug && dynamiqtec2 ?
                                            <Button type='link' onClick={() => navigate(`/product-review/${dynslug}`)}>Read more</Button> : slug && dynamiqtec1 ?
                                                <Button type='link' onClick={() => navigate(`/guides/${dynslug}`)}>Read more</Button> : slug &&
                                                <Button type='link' onClick={() => navigate(`/article/${slug}`)}>Read more</Button>
                                    }
                                </p>
                            );
                        }

                        return (
                            <p key={index} className='article-content mt-3'>
                                {cleanedParagraph}
                                {slug && !dynamiqtec1 && !dynamiqtec2 ?
                                    <Button type='link' onClick={() => navigate(`/article/${slug}`)}>Read more</Button> : slug && dynamiqtec2 ?
                                        <Button type='link' onClick={() => navigate(`/product-review/${dynslug}`)}>Read more</Button> : slug && dynamiqtec1 ?
                                            <Button type='link' onClick={() => navigate(`/guides/${dynslug}`)}>Read more</Button> : slug &&
                                            <Button type='link' onClick={() => navigate(`/article/${slug}`)}>Read more</Button>
                                }
                            </p>
                        );
                    })}
                </div>



                <Space>
                    <Button className={`btn-icon ${isArticleLiked ? 'liked' : ''}`} icon={<LikeOutlined />} onClick={handleLike}>
                    </Button>
                    {likes &&
                        <Badge
                            style={{ backgroundColor: '#192655' }}
                            count={localLikes}
                        />}
                    {/* <Button className='btn-icon' icon={<DislikeOutlined />} onClick={handleDislike}>
                </Button>
                {dislikes &&
                    <Badge
                        style={{ backgroundColor: '#192655' }}
                        count={dislikes}
                    />} */}
                    <Button className='btn-icon' icon={<ShareAltOutlined />} onClick={handleShare}>
                        Share
                    </Button>
                    {share &&
                        <Badge
                            style={{ backgroundColor: '#192655' }}
                            count={localShares}
                        />}
                </Space>
                {source &&
                    <Space className='float-end'>
                        <strong>Source:</strong>
                        <a href={source_link} target="_blank" rel="noopener noreferrer">{source}</a>
                    </Space>
                }
            </Card >
        </>
    );
};

export default Article;