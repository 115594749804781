import React from 'react';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from './auth/Login';
import Register from './auth/Register';
import Main from './component/Main';
import AdminLogin from './auth/AdminLogin';
import AdminDash from './component/Admin/AdminDash';
import ManageArticles from './component/Admin/ManageArticles';
import ManageReviews from './component/Admin/ManageReviews';
import CreateHeadlines from './component/Admin/CreateHeadlines';
import ManageGuide from './component/Admin/ManageGuide';
import CookieConsent from './CookieConsent'
import Subscribe from './component/Subscribe';
import ManageNewsletter from './component/Admin/ManageNewsletter';
import ManageMessages from './component/Admin/ManageMessages';

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/admin/login" element={<AdminLogin />} />
          <Route path="/admin/dashboard" element={<AdminDash />} />
          <Route path="/admin/create" element={<CreateHeadlines />} />
          <Route path="/admin/manage" element={<ManageArticles />} />
          <Route path="/admin/manage-reviews" element={<ManageReviews />} />
          <Route path="/admin/manage-guide" element={<ManageGuide />} />
          <Route path="/admin/newsletter" element={<ManageNewsletter />} />
          <Route path="/admin/manage-messages" element={<ManageMessages />} />
          <Route path='/signup' element={<Register />} />
          <Route path='*' element={<Main />} />

          <Route path='/home' element={<Main />} />
        </Routes>
      </Router>
      <CookieConsent />
      <Subscribe />

    </div>
  );
}

export default App;
