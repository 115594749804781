import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';

const CookieConsent = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const consentGiven = localStorage.getItem('cookieConsent');
        if (!consentGiven) {
            const timer = setTimeout(() => {
                setIsVisible(true);
            }, 30000);
            return () => clearTimeout(timer);
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'true');
        setIsVisible(false);
    };

    const handleDecline = () => {
        localStorage.setItem('cookieConsent', 'false');
        setIsVisible(false);
    };

    return (
        <Modal
            title="Cookie Consent"
            visible={isVisible}
            footer={[
                <Button key="decline" onClick={handleDecline}>
                    Decline
                </Button>,
                <Button key="accept" type="primary" onClick={handleAccept}>
                    Accept
                </Button>
            ]}
        >
            <p>We use cookies to ensure that we give you the best experience on our website. Do you accept the use of cookies?</p>
        </Modal>
    );
};

export default CookieConsent;
