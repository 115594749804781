import React, { useEffect, useState, useCallback } from 'react';
import { Button, Card, Col, Form, Input, Row, Select, Spin, Switch, Typography, Upload, notification } from 'antd';
import { ProductAxiosInstance } from '../AxiosInstance';
import { FaSpinner } from 'react-icons/fa6';
import { UploadOutlined } from '@ant-design/icons';
import BackButton from '../BackButton';
import { debounce } from 'lodash';
import Logo from '../logo.png'
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import Logout from './Logout'

const { Option } = Select;
const { Title } = Typography;

interface Product {
    update_review: boolean;
    id: number;
    name: string;
    description: string;
    image: string | null;
    slug: string;
}

interface CategoryFolder {
    id: number;
    name: string;
    products: Product[];
}

interface Category {
    id: number;
    name: string;
    category_folders: CategoryFolder[];
}

const ManageReviews: React.FC = () => {
    const [categories, setCategories] = useState<Category[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [image, setImage] = useState<File | null>(null);
    const [filteredFolders, setFilteredFolders] = useState<CategoryFolder[]>([]);
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [form1] = Form.useForm();
    const [form2] = Form.useForm();
    const [form3] = Form.useForm();
    const navigate = useNavigate()
    useEffect(() => {
        const admin = localStorage.getItem('admin')
        if (!admin) {
            toast.error('Unauthorized')

            setTimeout(() => {
                navigate('/admin/login', { replace: true })
            }, 2000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fetchData = useCallback(debounce(async () => {
        try {
            const response = await ProductAxiosInstance.get('/classified-products/');
            setCategories(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    }, 500), []);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const handleCreateCategory = async (values: any) => {
        try {
            await ProductAxiosInstance.post('/categories/', values);
            fetchData();
            notification.success({
                message: 'Success',
                description: 'Category created successfully',
            });
            form1.resetFields();
        } catch (error) {
            console.error('Error creating category:', error);
            notification.error({
                message: 'Error',
                description: 'Failed to create category',
            });
        }
    };

    const handleCreateCategoryFolder = async (values: any) => {
        try {
            await ProductAxiosInstance.post('/categoryfolders/', values);
            fetchData();
            notification.success({
                message: 'Success',
                description: 'Category folder created successfully',
            });
            form2.resetFields();
        } catch (error) {
            console.error('Error creating category folder:', error);
            notification.error({
                message: 'Error',
                description: 'Failed to create category folder',
            });
        }
    };

    const handleCreateProduct = async (values: any) => {
        try {
            const formData = new FormData();
            formData.append('name', values.name);
            formData.append('description', values.description);
            formData.append('category', values.category);
            formData.append('category_folder', values.category_folder);
            formData.append('update_review', 'true');
            if (image) {
                formData.append('image', image);
            }

            await ProductAxiosInstance.post('/products/', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            fetchData();
            notification.success({
                message: 'Success',
                description: 'Product created successfully',
            });
            form3.resetFields();
        } catch (error) {
            console.error('Error creating product:', error);
            notification.error({
                message: 'Error',
                description: 'Failed to create product',
            });
        }
    };

    const handleDeleteCategory = async (id: number) => {
        try {
            await ProductAxiosInstance.delete(`/categories/${id}/`);
            fetchData();
            notification.success({
                message: 'Success',
                description: 'Category deleted successfully',
            });
        } catch (error) {
            console.error('Error deleting category:', error);
            notification.error({
                message: 'Error',
                description: 'Failed to delete category',
            });
        }
    };

    const handleDeleteCategoryFolder = async (id: number) => {
        try {
            await ProductAxiosInstance.delete(`/categoryfolders/${id}/`);
            fetchData();
            notification.success({
                message: 'Success',
                description: 'Category folder deleted successfully',
            });
        } catch (error) {
            console.error('Error deleting category folder:', error);
            notification.error({
                message: 'Error',
                description: 'Failed to delete category folder',
            });
        }
    };

    const handleDeleteProduct = async (id: number) => {
        try {
            await ProductAxiosInstance.delete(`/products/${id}/`);
            fetchData();
            notification.success({
                message: 'Success',
                description: 'Product deleted successfully',
            });
        } catch (error) {
            console.error('Error deleting product:', error);
            notification.error({
                message: 'Error',
                description: 'Failed to delete product',
            });
        }
    };

    const handleUpdateReviewStatus = async (id: number, update_review: boolean) => {
        try {
            await ProductAxiosInstance.patch(`/products/${id}/`, { update_review });
            fetchData();
            notification.success({
                message: 'Success',
                description: 'Product review status updated successfully',
            });
        } catch (error) {
            console.error('Error updating review status:', error);
            notification.error({
                message: 'Error',
                description: 'Failed to update review status',
            });
        }
    };

    const handleImageChange = (info: any) => {
        if (info.file.status === 'done') {
            setImage(info.fileList[0].originFileObj);
        }
    };

    const handleCategoryChange = (categoryId: number) => {
        const selectedCategory = categories.find(category => category.id === categoryId);
        if (selectedCategory) {
            setFilteredFolders(selectedCategory.category_folders);
        }
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(e.target.value.toLowerCase());
    };

    const filteredCategories = categories.filter(category =>
        category.name.toLowerCase().includes(searchQuery) ||
        category.category_folders.some(folder =>
            folder.name.toLowerCase().includes(searchQuery) ||
            folder.products.some(product => product.name.toLowerCase().includes(searchQuery))
        )
    );

    if (loading) {
        return <center><Spin indicator={<FaSpinner className="spinner" />} spinning={loading} /></center>;
    }

    return (
        <div className='m-3'>
            <Logout />
            <center>
                <img
                    src={Logo}
                    alt="Dynamiqtec Logo"
                    className='logo'
                    onClick={() => navigate('/')}
                />
            </center>
            <h1 className='my-title m-3'>Manage Reviews</h1>
            <BackButton />
            <Input.Search
                placeholder="Search for a category, folder, or product"
                onChange={handleSearchChange}
                style={{ marginBottom: 20 }}
                className='search-input'
            />

            <Row gutter={16} className='mt-4'>
                <Col span={8}>
                    <Card title="Create Category">
                        <Form onFinish={handleCreateCategory} form={form1}>
                            <Form.Item name="name" label="Category Name" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">Create</Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="Create Category Folder">
                        <Form onFinish={handleCreateCategoryFolder} form={form2}>
                            <Form.Item name="category" label="Category" rules={[{ required: true }]}>
                                <Select onChange={handleCategoryChange}>
                                    {categories.map((category: any) => (
                                        <Option key={category.id} value={category.id}>{category.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item name="name" label="Category Folder Name" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">Create</Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
                <Col span={8}>
                    <Card title="Create Product">
                        <Form onFinish={handleCreateProduct} form={form3}>
                            <Form.Item name="name" label="Product Name" rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                            <Form.Item name="description" label="Product Description">
                                <Input.TextArea />
                            </Form.Item>
                            <Form.Item name="category" label="Category" rules={[{ required: true }]}>
                                <Select onChange={handleCategoryChange}>
                                    {categories.map((category: any) => (
                                        <Option key={category.id} value={category.id}>{category.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item name="category_folder" label="Category Folder" rules={[{ required: true }]}>
                                <Select>
                                    {filteredFolders.map((folder: any) => (
                                        <Option key={folder.id} value={folder.id}>{folder.name}</Option>
                                    ))}
                                </Select>
                            </Form.Item>
                            <Form.Item name="image" label="Product Image">
                                <Upload
                                    beforeUpload={() => false}
                                    onChange={handleImageChange}
                                    maxCount={1}
                                    accept=".jpg,.jpeg,.png,.gif,.bmp"
                                >
                                    <Button icon={<UploadOutlined />}>Upload Image</Button>
                                </Upload>
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">Create</Button>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>

            {filteredCategories.map((category) => (
                <div key={category.id} style={{ marginBottom: '40px' }}>
                    <Title className='category-name' level={2}>{category.name}
                        <Button className='m-2' type="dashed" onClick={() => handleDeleteCategory(category.id)} danger>Delete</Button>
                    </Title>
                    <Row gutter={[16, 16]}>
                        {category.category_folders.map((folder) => (
                            <Col key={folder.id} xs={24} sm={12} md={8} lg={8}>
                                <Card
                                    hoverable
                                    title={folder.name}
                                    extra={
                                        <Button type="dashed" onClick={() => handleDeleteCategoryFolder(folder.id)} danger>Delete</Button>}
                                >
                                    <ul className='product-ul'>
                                        {folder.products.map((product) => (
                                            <li className='product-li-adm' key={product.id}>
                                                {product.name}
                                                <Switch
                                                    checked={product.update_review}
                                                    onChange={(checked) => handleUpdateReviewStatus(product.id, checked)}
                                                    checkedChildren="Update Review"
                                                    unCheckedChildren="No Update"
                                                />
                                                <Button type="link" onClick={() => handleDeleteProduct(product.id)} >Delete</Button>
                                            </li>
                                        ))}
                                    </ul>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </div>
            ))}
        </div>
    );
};

export default ManageReviews;