import React, { useEffect } from 'react'
import { Button, Card, Space } from 'antd';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import Logo from '../logo.png'
import Logout from './Logout'

const AdminDash = () => {
    const navigate = useNavigate()
    const userName = localStorage.getItem('admin-name')
    useEffect(() => {
        const admin = localStorage.getItem('admin')
        if (!admin) {
            toast.error('Unauthorized')

            setTimeout(() => {
                navigate('/admin/login', { replace: true })
            }, 2000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <center>
            <Toaster />
            <img
                src={Logo}
                alt="Dynamiqtec Logo"
                className='logo'
                onClick={() => navigate('/')}
            />
            <h1 className='title mt-3 mb-5'>Welcome to Dynamiqtec Admin Dashboard {userName}</h1>
            <Logout />
            <Space wrap className='mt-5 mx-auto'>
                <Card className='m-5' style={{ width: 350 }}>
                    <h3>Manage Headlines</h3>
                    <Button onClick={() => navigate('/admin/create')} type="primary">Click to go</Button>
                </Card>
                <Card className='m-5' style={{ width: 350 }}>
                    <h3>Manage Articles</h3>
                    <Button onClick={() => navigate('/admin/manage')} type="primary">Click to go</Button>
                </Card>
                <Card className='m-5' style={{ width: 350 }}>
                    <h3>Product Reviews</h3>
                    <Button onClick={() => navigate('/admin/manage-reviews')} type="primary">Click to go</Button>
                </Card>
                <Card className='m-5' style={{ width: 350 }}>
                    <h3>Guide and Comparisons</h3>
                    <Button onClick={() => navigate('/admin/manage-guide')} type="primary">Click to go</Button>
                </Card>
                <Card className='m-5' style={{ width: 350 }}>
                    <h3>Newsletter</h3>
                    <Button onClick={() => navigate('/admin/newsletter')} type="primary">Click to go</Button>
                </Card>
                <Card className='m-5' style={{ width: 350 }}>
                    <h3>Messages</h3>
                    <Button onClick={() => navigate('/admin/manage-messages')} type="primary">Click to go</Button>
                </Card>
            </Space>
        </center>
    )
}

export default AdminDash;